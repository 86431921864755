<template>
  <default-layout :searchFunction="loadData" searchParamString="query">
    <v-container id="user-management" fluid tag="section">
      <page-loading :show="isLoading" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <user-list
        :items="users"
        :meta="meta"
        :cities="cities"
        :permissions="permissions"
        @changePage="changePage"
        @changeOtpFilter="changeOtpFilter"
        @changeCities="changeCities"
        @changeIdentityFilter="changeIdentityFilter"
        @changeAgentOfficeFilter="changeAgentOfficeFilter"
        @changeSalesFilter="changeSalesFilter"
        @changeDateFilter="changeDateFilter"
      ></user-list>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';

const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const UserList = () => import('@/components/user-management/beliruma-user/list');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    UserList,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
      otpFilterId: null,
      identityFilterId: null,
      cityFilterIds: null,
      agentOfficeFilter: null,
      salesFilter: null,
      minDateFilter: null,
      maxDateFilter: null,
    };
  },
  computed: {
    ...mapState({
      permissions: (state) => state.belirumaUser.permissions,
      users: (state) => state.belirumaUser.users,
      cities: (state) => state.belirumaUser.cities,
      meta: (state) => state.belirumaUser.meta,
      page: (state) => state.belirumaUser.page,
    }),
  },
  async mounted() {
    try {
      this.isLoading = true;
      let query = this.$route.query;
      this.$store.commit('belirumaUser/RESET_DEFAULT');
      const response = await this.$store.dispatch('belirumaUser/getInitData', query);
      console.log('CHECK USER INIT DATA: ', response);
    } catch (e) {
      this.showAlert = true;
      this.messageAlert = e;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async loadData(query) {
      try {
        this.isLoading = true;
        const response = await this.$store.dispatch('belirumaUser/getData', query);
        console.log('GET USER DATA: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      } finally {
        this.isLoading = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    async changePage(page) {
      let query = this.$route.query;
      query.page = page;
      query.area_specialization_ids = this.cityFilterIds;
      query.otp_filter_id = this.otpFilterId;
      query.id_verif_filter_id = this.identityFilterId;
      query.agent_office_filter = this.agentOfficeFilter;
      query.sales_filter = this.salesFilter;
      query.min_date = this.minDateFilter;
      query.max_date = this.maxDateFilter;
      await this.loadData(query);
    },
    async changeCities(val) {
      let query = this.$route.query;
      this.cityFilterIds = query.area_specialization_ids = val;
      await this.loadData(query);
    },
    async changeOtpFilter(val) {
      let query = this.$route.query;
      query.page = 1;

      let filterId = null;
      switch (val) {
        case 'Sudah Verifikasi Telepon': {
          filterId = 1;
          break;
        }
        case 'Belum Verifikasi Telepon': {
          filterId = 2;
          break;
        }
      }
      this.otpFilterId = query.otp_filter_id = filterId;
      await this.loadData(query);
    },
    async changeIdentityFilter(val) {
      let query = this.$route.query;
      query.page = 1;

      let filterId = null;
      switch (val) {
        case 'Sudah Verifikasi Identitas': {
          filterId = 1;
          break;
        }
        case 'Belum Verifikasi Identitas': {
          filterId = 2;
          break;
        }
      }
      this.identityFilterId = query.id_verif_filter_id = filterId;
      await this.loadData(query);
    },
    async changeAgentOfficeFilter(val) {
      let query = this.$route.query;
      query.page = 1;
      if (val === 'Kantor Belum Terdaftar') {
        this.agentOfficeFilter = query.agent_office_filter = true;
      } else {
        this.agentOfficeFilter = query.agent_office_filter = null;
      }
      await this.loadData(query);
    },
    async changeSalesFilter(val) {
      let query = this.$route.query;
      query.page = 1;
      if (val === 'Diri Sendiri') {
        this.salesFilter = query.sales_filter = true;
      }
      // else if (val === 'Selain Diri Sendiri') {
      //   this.salesFilter = query.sales_filter = false;
      // }
      else {
        this.salesFilter = query.sales_filter = val;
      }
      await this.loadData(query);
    },
    async changeDateFilter(val) {
      let query = this.$route.query;
      query.page = 1;

      this.minDateFilter = query.min_date = val ? val[0] : null;
      this.maxDateFilter = query.max_date = val ? val[1] : null;
      await this.loadData(query);
    },
  },
};
</script>
